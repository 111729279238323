import React, { Component } from 'react';

import './App.css';
import Home from './Components/Pages/Home';

class App extends Component {
  constructor() {
    super();

    this.state = {
      posts: []
    };
  }

  componentDidMount() {
    fetch('https://perrk.herokuapp.com/featured/post/marketing')
      .then(response => {
        if (!response.ok) {
          throw Error('Error fetching images')
        }

        return response.json();
      })
      .then(allData => {
        this.setState({
          posts: allData
        });
      })
      .catch(err => {
        console.log('****error: ', err)
        throw Error(err.message);
      })
  };

  render() {
    return (
      <section className='app'>
        <Home posts={this.state.posts} />
      </section>
    )
  }
}

export default App;
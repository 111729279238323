import React from 'react'

import '../../App.css'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import BodyContainer from '../BodyContainer/BodyContainer'

const Home = (props) => {
    return (
        <>
        <Header />
        <BodyContainer posts={props.posts}/>
         <Footer />
        </>
    )
}

export default Home;
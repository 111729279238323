import React, { useState } from 'react';

import './GIFContainer.css'
import previousArrow from '../../pictures/left-arrow-enabled.svg';
import previousArrowDisabled from '../../pictures/left-arrow-disabled.svg';
import nextArrow from '../../pictures/right-arrow-enabled.svg';
import nextArrowDisabled from '../../pictures/right-arrow-disabled.svg';
import defaultPerrkHighlight from '../../pictures/perrk-default-highlight.gif'

import jinxGIF from '../../pictures/gifs/jinx.gif'
import tolemaGIF from '../../pictures/gifs/tolema.gif'
import tixtsGIF from '../../pictures/gifs/tixts.gif'
import w000wGIF from '../../pictures/gifs/w000w.gif'
import faeGIF from '../../pictures/gifs/fae.gif'


const posts = [
    {
        username: 'W000W',
        gif: w000wGIF
    },
    {
        username: 'TOLEMA',
        gif: tolemaGIF
    },
    {
        username: 'TIXTS',
        gif: tixtsGIF
    },
    {
        username: 'FAE',
        gif: faeGIF
    },
    {
        username: 'JINX',
        gif: jinxGIF
    }
]

const GIFContainer = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const isPreviousButtonDisabled = currentIndex === 0;
    const isNextButtonDisabled = currentIndex === posts.length - 1;

    const nextButtonImage = isNextButtonDisabled
        ? nextArrowDisabled
        : nextArrow;

    const previousButtonImage = isPreviousButtonDisabled
        ? previousArrowDisabled
        : previousArrow;

    const handlePreviousClick = () => {
        if (!posts || posts.length === 0) {
            return
        }

        const newIndex = currentIndex - 1
        
        if (newIndex < 0) {
            return
        }

        setCurrentIndex((currentIndex) => currentIndex - 1);
    };

    const handleNextClick = () => {
        if (!posts || posts.length === 0) {
            return
        }
        const newIndex = currentIndex + 1

        if (newIndex > (posts.length - 1)) {
            return
        }

        setCurrentIndex((currentIndex) => currentIndex + 1);
    };

    const getCurrentPost = () => {
        if (!posts || posts.length === 0) {
            return null
        }

        const clampedIndex = Math.max(
            0,
            Math.min(currentIndex, posts.length - 1)
        );

        return posts[clampedIndex]
      };

    return (
        <div className='gif-container-box'>
            <button className='previous-button' onClick={handlePreviousClick} disabled={isPreviousButtonDisabled}>
                <img src={posts.length > 0 ? previousButtonImage : ''}/>
            </button>

            <div className='gif-card'>
                <>
                <img
                    className='current-gif'
                    src={posts.length > 0 ? getCurrentPost().gif : defaultPerrkHighlight}
                    alt=''
                />
                
                <div className='username-container'>
                    <div className='username-content'>
                        { posts.length > 0 ? (getCurrentPost().username || '').toUpperCase() : 'PERRK'}
                    </div>
                </div>
                </>
            </div>
            
            <button className='next-button' onClick={handleNextClick} disabled={isNextButtonDisabled}>
                <img src={posts.length > 0 ? nextButtonImage : ''}/>
            </button>
        </div>
    )
}

export default GIFContainer;
import React from 'react';

import './SignUpBox.css'
import qrcode from '../../pictures/app-store-qr-code.svg'
import appleAppStoreIcon from "../../pictures/apple-button.svg";

const SignUpBox = () => {
    return (
        <div className='sign-up-box'> 
            <div className ="catch-phrase-container">
                <h2 className="catch-phrase-one">IN MOTION.</h2>
            </div>

            <div className="qr-code-container">
                <img className="qr-code" src={qrcode} alt=""/>

                <div className='apple-app-store-icon'>
                    <a href='https://apps.apple.com/us/app/perrk/id1600095913' target='_blank'>
                        <img src={appleAppStoreIcon} alt="" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default SignUpBox; 
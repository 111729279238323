import React from 'react'
import './Header.css'
import logo from '../../pictures/logo.png'

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <img src={logo} alt="Logo" className="header-image" />
        <h1 className="header-title">PERRK</h1>
      </div>
    </header>
  );
};

export  default Header;
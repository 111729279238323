import React from 'react'

import './BodyContainer.css'
import SignUpBox from '../DownloadAppContainer/SignUpBox'
import GIFContainer from '../GIFContainer/GIFContainer';

const BodyContainer = (props) => {
  return (
    <div className="body">
      <div className="left-container">{<GIFContainer posts={props.posts}/>}</div>
      <div className="right-container">{<SignUpBox/>}</div>
    </div>
  );
};

export  default BodyContainer;

import React from 'react'
import './FooterStyles.css'

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <p className="footer-text">
        &copy; {currentYear} PERRK LLC. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;